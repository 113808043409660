import React from "react";
import { Footer } from "../../elements/Footer/Footer";
import { PageHeader } from "../../elements/PageHeader/PageHeader";
import { ProductsSection1 } from "./components/ProductsSection1";

export const Products = () => {
  return (
    <>
      <PageHeader
        headerBg={"/assets/images/products-header.webp"}
        pageTitle="Our Products"
      />
      <ProductsSection1 />
      <Footer />
    </>
  );
};
