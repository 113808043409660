import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import {
  BodyContainerSection3,
  HomeSection5,
  Section3Title,
  Section5CardItem,
  Section5CardItemCaption,
  Section5CardItemIcon,
  Section5CardItemTitle,
  Section5CardWrapper,
} from "../HomeStyle";

const whyIndianMarketsAreBest = [
  {
    icon: "/assets/images/agriculture.svg",
    title: "Agriculture Base",
    caption:
      "The over 50 per cent of india’s workforce involved in the agriculture sector, the country is once of the leading agriculture exporters.",
  },
  {
    icon: "/assets/images/best-quality.svg",
    title: "Best Quality Food Products",
    caption:
      "Any food product manufactured in India goes through quality assurance tests and certifications to ensure that they are of high quality.",
  },
  {
    icon: "/assets/images/diversity.svg",
    title: "Diversity of India and Indian Culture",
    caption:
      "The diverse Indian cultures are the reason behind the diversified food products available. With each region of the country possessing its own blend and flavour, no country does this better.",
  },
  {
    icon: "/assets/images/rates.svg",
    title: "Affordable Rates",
    caption:
      "Cheap labour and raw materials allow India to produce spices, oils, and other food products at great affordable rates.",
  },
  {
    icon: "/assets/images/variety.svg",
    title: "Variety of Spices and Tastes",
    caption:
      "Being known as the land of spices, the country is the leading producer of spices. The country boasts of a huge variety of spices providing a great range of flavour.",
  },
  {
    icon: "/assets/images/tech.svg",
    title: "Technology and Innovation",
    caption:
      "In a land blessed with great scientific minds, India’s technological advancements and innovation have made international trade all the easier, making it the perfect choice.",
  },
];

export const HomePageSection5 = () => {
  return (
    <HomeSection5>
      <BodyContainerSection3>
        <Zoom>
          <Section3Title>Why the Indian Market are Best?</Section3Title>
        </Zoom>
        <Fade bottom>
          <Section5CardWrapper>
            {whyIndianMarketsAreBest.map((item, index) => {
              return (
                <Section5CardItem key={index}>
                  <Section5CardItemIcon>
                    <img src={item.icon} alt={item.title} />
                  </Section5CardItemIcon>
                  <Section5CardItemTitle>{item.title}</Section5CardItemTitle>
                  <Section5CardItemCaption>
                    {item.caption}
                  </Section5CardItemCaption>
                </Section5CardItem>
              );
            })}
          </Section5CardWrapper>
        </Fade>
      </BodyContainerSection3>
    </HomeSection5>
  );
};
