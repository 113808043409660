import React from "react";
import Zoom from "react-reveal/Zoom";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { Footer } from "../../elements/Footer/Footer";
import { Header } from "../../elements/Header/Header";
import { HomePageSection1 } from "./components/HomePageSection1";
import { HomePageSection2 } from "./components/HomePageSection2";
import { HomePageSection3 } from "./components/HomePageSection3";
import { HomePageSection4 } from "./components/HomePageSection4";
import { HomePageSection5 } from "./components/HomePageSection5";
import { HomePageSection6 } from "./components/HomePageSection6";
import {
  HomePageBannerContent,
  HomePageSub,
  HomePageTitle,
  OutlinedBtn,
  PageHeaderWrapper,
} from "./HomeStyle";
export const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Header */}
      <PageHeaderWrapper bg={"/assets/images/home-bg.webp"}>
        <Header marginTop={0} />
        <HomePageBannerContent>
          <HomePageTitle>SPK International Exports</HomePageTitle>
          <Zoom>
            <HomePageSub>
              <Typewriter
                options={{
                  strings: ["Premium Quality. At Competitive Price."],
                  autoStart: true,
                  loop: true,
                  delay: "100",
                  cursor: "",
                  onCreateTextNode: null,
                }}
              />
               
            </HomePageSub>
          </Zoom>
          <Zoom>
            <OutlinedBtn onClick={() => navigate("/contact")}>
              Get in Touch
            </OutlinedBtn>
          </Zoom>
        </HomePageBannerContent>
      </PageHeaderWrapper>
      {/* Section 1 */}
      <HomePageSection1 />
      {/* Section 2 */}
      <HomePageSection2 />
      {/* Section 3 */}
      <HomePageSection3 />
      {/* Section 4 */}
      <HomePageSection4 />
      <HomePageSection6 />
      {/* Section 5 */}
      <HomePageSection5 />

      {/* Footer */}

      <Footer />
    </>
  );
};
