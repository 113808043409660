import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  HeaderContainer,
  HeaderLogo,
  HeaderWrapper,
  NavList,
  NavListItem,
  NavSection,
} from "./HeaderStyle";

export const Header = ({ marginTop }) => {
  const location = useLocation();
  return (
    <HeaderWrapper marginTop={marginTop}>
      <HeaderContainer>
        <Link to={"/"}>
          <HeaderLogo>
            <img
              src="/assets/logo/skpinternationalexports-logo.svg"
              alt="logo"
            />
            {/* <img src="/assets/logo/logo-name.svg" alt="SPK" /> */}
          </HeaderLogo>
        </Link>
        <NavSection>
          <NavList>
            <Link to={"/"}>
              <NavListItem active={location.pathname === "/"}>Home</NavListItem>
            </Link>
            <Link to={"/about"}>
              <NavListItem active={location.pathname === "/about"}>
                About Us
              </NavListItem>
            </Link>
            <Link to={"/products"}>
              <NavListItem active={location.pathname === "/products"}>
                Products
              </NavListItem>
            </Link>
            {/* <Link to={"/gallery"}>
            <NavListItem active={location.pathname === "/gallery"}>
              Gallery
            </NavListItem>
          </Link> */}
            <Link to={"/contact"}>
              <NavListItem active={location.pathname === "/contact"}>
                Contact Us
              </NavListItem>
            </Link>
          </NavList>
        </NavSection>
      </HeaderContainer>
    </HeaderWrapper>
  );
};
