import React from "react";
import { Fade } from "react-reveal";
import {
  AboutSection4Container,
  AboutSection4Wrapper,
  LeftSideSection4,
  LeftSideSection4Item,
  Section4Ans,
  Section4Ques,
} from "../AboutStyle";

export const AboutSection4 = () => {
  return (
    <AboutSection4Wrapper>
      <AboutSection4Container>
        <LeftSideSection4>
          <Fade bottom>
            <LeftSideSection4Item>
              <Section4Ques>Who We Are</Section4Ques>
              <Section4Ans>
                We are young ladies (sisters) who started the export business
                after our dad passed away. Our dad never believed his daughters
                can do Export & Import Business. After he passed away 2 years
                back, my sister & myself took over the company. We wanted to
                prove Women can also succeed in Export & Import business.
              </Section4Ans>
            </LeftSideSection4Item>
            <LeftSideSection4Item>
              <Section4Ques>What We Do?</Section4Ques>
              <Section4Ans>
                We provide customers with the service of exporting food products
                from India. But we also understand the demand for Indian food,
                which is why we also offer all kinds of food export from India.
                From helping you find the right buyers to packaging your food
                and sending it off, our complete services will ensure that you
                face absolutely no difficulties. Be it Europe, middle-east, the
                Americas, or wherever you want the food to be exported, we make
                sure that the job is done.
              </Section4Ans>
            </LeftSideSection4Item>
          </Fade>
        </LeftSideSection4>
        {/* <RightSideSection4>
          <Fade right>
            <div>
              <img
                src="/assets/images/Jyothi-Gandhi.jpg "
                alt="Shobha Hasisni"
              />
              <PersonName>Jyothi Gandhi</PersonName>
              <PersonDesignation>Founder</PersonDesignation>
            </div>
            <div>
              <img src="/assets/images/Shobha-Hasini.jpg" alt="Jyothi Gandhi" />
              <PersonName>Shobha Hasisni</PersonName>
              <PersonDesignation>Co-Founder</PersonDesignation>
            </div>
          </Fade>
        </RightSideSection4> */}
      </AboutSection4Container>
    </AboutSection4Wrapper>
  );
};
