import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import {
  BodyContainerSection3,
  HomeSection3,
  LeftBorderCardItem,
  LeftBorderCardItemDesc,
  LeftBorderCardItemTitle,
  ListWrapper,
  Section3Title,
} from "../HomeStyle";

const whyChooseUsData = [
  {
    title: "Get Export Quality Food Products",
    desc: "No matter the type of food or spice you plan on exporting, we will ensure that you get genuine products which meet your expectations. ",
  },
  {
    title: "Best quality food trading services",
    desc: "Our stringent policies make sure that food handling and trading is in accordance with international quality standards. ",
  },
  {
    title: "Global Presence",
    desc: "Even if you are planning to export across multiple continents, we’ve got you covered. Our global presence will allow your products to be exported to various countries.  ",
  },
  {
    title: "Market Analysis & Up-to-date Market Information  ",
    desc: "Our thorough and regular analysis and research let us update our database with up to date information on the market and allows us to stay on top of every update.",
  },
  {
    title: "Reliable & Trusted Services  ",
    desc: "Trust is a pillar of our services. Our transparent and reliable operations ensure that you feel at ease with our services.  ",
  },
  {
    title: "Save your time & cost for searching markets  ",
    desc: "Understanding and researching the market by yourself can be expensive and time-consuming. But with Pisum, we will do that for you instead.  ",
  },
];

export const HomePageSection4 = () => {
  return (
    <HomeSection3>
      <BodyContainerSection3>
        <Zoom>
          <Section3Title>Why Choose Us?</Section3Title>
          {/* <Section3Caption>
            lorem ipsum it the simply dummy text lorem ipsum is the simply dummy
            text
          </Section3Caption> */}
        </Zoom>
      </BodyContainerSection3>
      <Fade big bottom>
        <ListWrapper>
          <div>
            {whyChooseUsData.map((item, index) => {
              return (
                <LeftBorderCardItem key={index}>
                  <span>{index + 1}</span>
                  <div>
                    <LeftBorderCardItemTitle>
                      {item.title}
                    </LeftBorderCardItemTitle>
                    <LeftBorderCardItemDesc>{item.desc}</LeftBorderCardItemDesc>
                  </div>
                </LeftBorderCardItem>
              );
            })}
          </div>
        </ListWrapper>
      </Fade>
    </HomeSection3>
  );
};
