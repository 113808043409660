import styled from "styled-components";

export const FooterWrapper = styled.div`
  background: #22356d;
  width: 100%;
`;
export const FooterContainer = styled.div`
  max-width: 1232px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 64px 16px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 4fr 2fr 2fr;
  overflow: hidden;
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 5;
`;

export const FooterLogoTitle = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  color: #ffffff;
  margin-top: -12px;
`;

export const FooterLogoDesc = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  margin-top: -12px;
`;

export const FooterTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
`;

export const FooterTitleItem = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin-top: -12px;
`;

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: -6px;
  > img {
    transition: 0.3s all ease;
    cursor: pointer;
    height: 40px;
    width: 40px;
    :active {
      transform: scale(0.95);
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const InputBox = styled.input`
  height: 34px;
  width: 215px;
  border: 1.5px solid #ffffff;
  color: #ffffff;
  padding: 0 8px;
  background: #00000000;
  outline: none;
  ::placeholder {
    font-weight: 400;
    font-size: 10px;
    color: #d4d7dd;
  }

  :-ms-input-placeholder {
    font-weight: 400;
    font-size: 10px;
    color: #d4d7dd;
  }

  ::-ms-input-placeholder {
    font-weight: 400;
    font-size: 10px;
    color: #d4d7dd;
  }
`;

export const EnquireBtn = styled.div`
  height: 34px;
  width: 100px;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "normal" : "pointer")};
  color: #22356d;
  background: #ffffff;
  transition: 0.3s all;
  :active {
    transform: ${(props) => (props.disabled ? "none" : "scale(0.95)")};
  }
`;

export const FooterBottom = styled.div`
  width: 100%;
  height: 44px;
  color: #d4d7dd;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d2c5a;
`;

export const FooterVectorArt = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  max-width: 1232px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  user-select: none;
`;
export const Vector1 = styled.img`
  width: 260px;
  height: 260px;
  transform: rotate(78.86deg);
`;
export const Vector2 = styled.img`
  width: 260px;
  height: 260px;
  transform: rotate(-42.28deg);
`;
