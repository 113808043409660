import styled from "styled-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  background: #fff;
  margin-top: ${(props) => props.marginTop};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1232px;
  padding: 0px 16px;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  background: #fff;
`;

export const HeaderLogo = styled.div`
  display: flex;
`;

export const NavSection = styled.nav`
  display: flex;
`;

export const NavList = styled.nav`
  display: flex;
  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }
`;

export const NavListItem = styled.li`
  list-style: none;
  text-decoration: none;
  text-style: none;
  padding: 12px 0 0;
  margin: 0 12px;
  color: #000;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s all ease;
  border-top: ${(props) =>
    props.active ? "2px solid #22356D" : "2px solid #00000000"};
`;
