import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { About } from "./components/pages/About/About";
import { Contact } from "./components/pages/Contact/Contact";
import { Home } from "./components/pages/Home/Home";
import { Products } from "./components/pages/Products/Products";
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route>
        <Route exact path="/products" element={<Products />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
