import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import {
  BodyContainerSection3,
  HomeSection3,
  ProductItemCard,
  ProductName,
  ProductsWrapper,
  Section3Caption,
  Section3Title,
} from "../HomeStyle";

const productsWeExportData = [
  {
    img: "/assets/images/basmati-rice.webp",
    name: "Basmati Rice",
  },
  {
    img: "/assets/images/spices.webp",
    name: "Indian Spices",
  },
  {
    img: "/assets/images/non-basmati-rice.webp",
    name: "Non-Basmati Rice",
  },
  {
    img: "/assets/images/fruits.webp",
    name: "Fruits",
  },
  {
    img: "/assets/images/honey.webp",
    name: "Kashmir Honey",
  },
  {
    img: "/assets/images/vegetables.webp",
    name: "Vegetables",
  },
  {
    img: "/assets/images/millets.webp",
    name: "Millets",
  },
  {
    img: "/assets/images/sticks.webp",
    name: "Incense Sticks",
  },
];

export const HomePageSection3 = () => {
  return (
    <HomeSection3>
      <BodyContainerSection3>
        <Zoom>
          <Section3Title>Products We Export</Section3Title>
          <Section3Caption>
            By focusing on delivering best Quality products to our customers, we
            are always available to help you with your particular needs and
            offer you a one-stop-shop solution for your next shipping request.
          </Section3Caption>
        </Zoom>
        <Fade bottom>
          <ProductsWrapper>
            {productsWeExportData.map((item, index) => {
              return (
                <ProductItemCard key={index}>
                  <img src={item.img} alt={item.name} />
                  <ProductName>{item.name}</ProductName>
                </ProductItemCard>
              );
            })}
          </ProductsWrapper>
        </Fade>
      </BodyContainerSection3>
    </HomeSection3>
  );
};
