import React from "react";
import { Footer } from "../../elements/Footer/Footer";
import { PageHeader } from "../../elements/PageHeader/PageHeader";
import { AboutSection1 } from "./components/AboutSection1";
import { AboutSection2 } from "./components/AboutSection2";
import { AboutSection3 } from "./components/AboutSection3";
import { AboutSection4 } from "./components/AboutSection4";

export const About = () => {
  return (
    <>
      <PageHeader
        headerBg={"/assets/images/about-header.webp"}
        pageTitle="About Us"
      />
      <AboutSection1 />
      <AboutSection3 />
      <AboutSection2 />
      <AboutSection4 />
      <Footer />
    </>
  );
};
