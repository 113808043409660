import {
  BodyContainerSection2,
  FilledBtn,
  HomeSection2,
  InternalBgSection2,
  Section2Content,
  Section2ContentDesc,
  Section2ContentTitle,
  Section2ImageWrapper,
} from "../HomeStyle";

import Fade from "react-reveal/Fade";

export const HomePageSection2 = () => {
  return (
    <HomeSection2>
      <InternalBgSection2 />
      <BodyContainerSection2>
        <Fade left>
          <Section2ImageWrapper>
            <img
              src="/assets/images/section2-image.webp"
              alt="high quality products"
            />
          </Section2ImageWrapper>
        </Fade>
        <Fade right>
          <Section2Content>
            <Section2ContentTitle>
              We aim to be a dedicated supplier of high-quality food products in
              India across Globe.
            </Section2ContentTitle>
            <Section2ContentDesc>
              Our goal is to provide High Quality Indian Food Products at farmer
              price. We strive to keep our products of outstanding quality,
              guaranteed food safety and grown under circumstances with
              attention to people and the environment.
            </Section2ContentDesc>
            <FilledBtn onClick={() => window.open("/contact", "_self")}>
              Get in Touch
            </FilledBtn>
          </Section2Content>
        </Fade>
      </BodyContainerSection2>
    </HomeSection2>
  );
};
