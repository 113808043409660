import styled from "styled-components";

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 720px;
  max-height: 100vh;
  background: linear-gradient(180deg, rgb(0, 0, 0, 0.7), #00000000),
    url(${(props) => props.bg});
  // background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;
export const VideoBg = styled.div`
  max-width: 1200px;
  position: absolute;
  z-index: 1;
  width: 100%;
  > video {
    width: 100%;
  }
`;
export const HomePageBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 156px auto 0;
  width: 100%;
  max-width: 1232px;
  padding: 0 16px;
  gap: 12px;
`;
export const HomePageTitle = styled.div`
  font-size: 48px;
  font-family: RhodiumLibre-Regular;
  color: #fff;
  line-height: 82px;
  span {
    font-family: RhodiumLibre-Regular;
  }
`;
export const HomePageSub = styled.div`
  color: #fff;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 36px;
`;

export const OutlinedBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border: 1px solid #ffffff;
  border-radius: 21px;
  height: 42px;
  width: 164px;
  color: #fff;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  font-weight: 500;
  :hover {
    background: #fff;
    color: #000;
  }
  :active {
    transform: scale(0.95);
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const HomeSection1 = styled.div`
  background: #fff;
  width: 100%;
  padding: 80px 0 80px;
`;

export const HomeSectionContent = styled.div`
  display: flex;
  max-width: 686px;
  flex-direction: column;
`;

export const SectionTypeText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #16213e;
  margin-bottom: 16px;
`;
export const HomeSection1Title = styled.div`
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  color: #16213e;
  margin-bottom: 24px;
`;
export const HomeSection1SubTitle = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #16213e;
  margin-bottom: 16px;
`;
export const HomeSection1Caption = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #686969;
  margin-bottom: 24px;
`;

export const HomeSection1ImageContainer = styled.div`
  padding: 24px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  background: #22356d;
  position: relative;
  height: 350px;
  width: 383px;
  padding: 20px 20px 0 0px;
  > video {
    position: absolute;
    top: 20px;
    right: 20px;
    object-fit: cover;
  }
  // width: 383px;
  // height: 350px;
  // > img {
  //   aspect-ratio: 409/369;
  //   max-width: 408px;
  //   // min-width: 250px;
  //   width: 100%;
  //   height: auto;
  //   z-index: 1;
  // }
`;
export const ImgBox = styled.div`
  position: absolute;
  aspect-ratio: 0.91;
  max-width: 383px;
  min-width: 250px;
  width: 100%;
  height: auto;
  background: #22356d;
  margin-left: 24px;
  z-index: 0;
`;

export const ReadMoreBtn = styled.div`
  background: #22356d;
  height: 64px;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  transition: 0.3s all ease;
  cursor: pointer;
  > img {
    transition: 0.3s all ease;
  }
  :hover {
    > img {
      transform: translate(8px, 0);
    }
  }
  :active {
    transform: scale(0.95);
  }
`;

export const HomeSection2 = styled.div`
  position: relative;
`;

export const BodyContainerSection2 = styled.div`
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const InternalBgSection2 = styled.div`
  background: #22356d;
  position: absolute;
  height: 80%;
  min-height: 320px;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
`;
export const Section2ImageWrapper = styled.div`
  z-index: 1;
  > img {
    aspect-ratio: 368/400;
    height: auto;
    max-width: 368px;
    width: 100%;
  }
`;

export const Section2Content = styled.div`
  z-index: 1;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  height: auto;
`;

export const Section2ContentTitle = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #fff;
  margin-bottom: 10px;
`;

export const Section2ContentDesc = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
`;

export const FilledBtn = styled.div`
  height: 45px;
  width: 165px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #22356d;
  transition: 0.3s all;
  :active {
    transform: scale(0.95);
  }
  &:hover {
    background: rgb(255, 255, 255, 0.8);
  }
`;

export const HomeSection3 = styled.div`
  background: #fff;
  width: 100%;
`;

export const BodyContainerSection3 = styled.div`
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  padding: 80px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
`;

export const Section3Title = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  color: #373737;
`;

export const Section3Caption = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #686969;
  margin-bottom: 10px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
`;

export const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px 20px;
  margin-top: 20px;
`;

export const ProductItemCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 0 calc(25% - 15px);
  gap: 0px;
  height: 214px;
  padding: 0;
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  margin-bottom: 12px;
  transition: 0.3s all;
  > img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: center;
  }
  :hover {
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.15);
  }
`;

export const ProductName = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #373737;
  padding: 12px 0;
`;

export const Section4ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const ListWrapper = styled.div`
  background: #22356d;
  // max-width: 1232px;
  width: 100%;
  padding: 48px 0 16px;
  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 auto;
    width: 100%;
    max-width: 1232px;
  }
`;
export const LeftBorderCardItem = styled.div`
  // background: #ffffff;
  // border-left: 7px solid #22356d;
  // box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.06);
  width: calc(50% - 12px);
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 145px;
  transition: 0.3s all;
  cursor: pointer;
  // :hover {
  //   border-left: 7px solid #7594f1;
  // }
  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  > span {
    margin-top: -24px;
    font-family: InriaSerif-Bold;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #ececec;
  }
`;
export const LeftBorderCardItemTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
`;
export const LeftBorderCardItemDesc = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
`;

export const HomeSection5 = styled.div`
  background: #f3f3f3;
  width: 100%;
  margin-top: 80px;
`;
export const Section5CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  margin-top: 48px;
`;

export const Section5CardItem = styled.div`
  width: calc(30%);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

export const Section5CardItemIcon = styled.div`
  > img {
    height: auto;
    width: auto;
  }
`;

export const Section5CardItemTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #373737;
`;

export const Section5CardItemCaption = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  text-align: center;
`;

export const WallPaperContainer = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 2fr;
  // grid-gap: 16px;
  // margin-top: 16px;

  // > img {
  //   transition: 0.3s all ease;
  //   cursor: pointer;
  //   :hover {
  //     transform: scale(1.01);
  //   }
  // }
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    > img {
      transition: 0.3s all ease;
      height: 262px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      cursor: pointer;
      :hover {
        transform: scale(1.01);
      }
    }
  }
`;
