import React from "react";
import { Fade, Zoom } from "react-reveal";
import {
  BodyContainerSection3,
  HomeSection5,
  Section3Title,
  Section5CardItem,
  Section5CardItemCaption,
  Section5CardItemIcon,
  Section5CardItemTitle,
  Section5CardWrapper,
} from "../../Home/HomeStyle";

const ourValues = [
  {
    icon: "/assets/images/agriculture.svg",
    title: "High quality food products",
    caption:
      "By Eliminating all intermediaries and providing first-hand produce directly from the farms we take matters into our hands so we can ensure the highest quality products available in the market.",
  },
  {
    icon: "/assets/images/best-quality.svg",
    title: "World class export quality",
    caption:
      "With modern technology, we keep a close eye on the product's condition, temperature, humidity, and location to ensure the greatest quality. We want to give food goods that are as good as those from farms.",
  },
  {
    icon: "/assets/images/diversity.svg",
    title: "Flexibility in negotiation",
    caption:
      "Customer satisfaction is a top priority, thus negotiations can be flexible and you can customize your order. How would you like the pricing, packing, or sorting to be done? Give us a call right away",
  },
];

export const AboutSection2 = () => {
  return (
    <HomeSection5>
      <BodyContainerSection3>
        <Zoom>
          <Section3Title>Our Values</Section3Title>
        </Zoom>
        <Fade bottom>
          <Section5CardWrapper>
            {ourValues.map((item, index) => {
              return (
                <Section5CardItem key={index}>
                  <Section5CardItemIcon>
                    <img src={item.icon} alt={item.title} />
                  </Section5CardItemIcon>
                  <Section5CardItemTitle>{item.title}</Section5CardItemTitle>
                  <Section5CardItemCaption>
                    {item.caption}
                  </Section5CardItemCaption>
                </Section5CardItem>
              );
            })}
          </Section5CardWrapper>
        </Fade>
      </BodyContainerSection3>
    </HomeSection5>
  );
};
