import React from "react";
import { Fade, Zoom } from "react-reveal";
import {
  BodyContainer,
  BodyWrapper,
  ProductCardItem,
  ProductName,
  ProductsCollectionWrapper,
  SectionCaption,
  SectionTitle,
} from "../ProductsStyle";

const products = [
  {
    image: "/assets/images/basmati-rice.webp",
    category: "Product name",
    name: "Basmati Rice",
  },
  {
    image: "/assets/images/spices.webp",
    category: "Product name",
    name: "Indian Spices",
  },
  {
    image: "/assets/images/non-basmati-rice.webp",
    category: "Product name",
    name: "Non-Basmati Rice",
  },
  {
    image: "/assets/images/fruits.webp",
    category: "Product name",
    name: "Fruits",
  },
  {
    image: "/assets/images/honey.webp",
    category: "Product name",
    name: "Honey",
  },
  {
    image: "/assets/images/vegetables.webp",
    category: "Product name",
    name: "Vegetables",
  },
  {
    image: "/assets/images/millets.webp",
    category: "Product name",
    name: "Millets",
  },
  {
    image: "/assets/images/sticks.webp",
    category: "Product name",
    name: "Incense Sticks",
  },
  {
    image: "/assets/images/flour.webp",
    category: "Product name",
    name: "All Purpose Flour",
  },
];

export const ProductsSection1 = () => {
  return (
    <BodyWrapper>
      <BodyContainer>
        <Zoom>
          <SectionTitle>Products We Export</SectionTitle>
          <SectionCaption>
            By focusing on delivering best Quality products to our customers, we
            are always available to help you with your particular needs and
            offer you a one-stop-shop solution for your next shipping request.
          </SectionCaption>
        </Zoom>
        <Fade big bottom>
          <ProductsCollectionWrapper>
            {products.map((item, index) => {
              return (
                <ProductCardItem key={index}>
                  <img src={item.image} alt={item.name} />
                  <ProductName>{item.name}</ProductName>
                  {/* <ProductCategory>{item.category}</ProductCategory> */}
                </ProductCardItem>
              );
            })}
          </ProductsCollectionWrapper>
        </Fade>
      </BodyContainer>
    </BodyWrapper>
  );
};
