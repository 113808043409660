import React from "react";
import { Footer } from "../../elements/Footer/Footer";
import { PageHeader } from "../../elements/PageHeader/PageHeader";
import { ContactSection1 } from "./components/ContactSection1";
import { ContactSection2 } from "./components/ContactSection2";

export const Contact = () => {
  return (
    <>
      <PageHeader
        headerBg={"/assets/images/contact-header.webp"}
        pageTitle="Contact Us"
      />
      <ContactSection1 />
      <ContactSection2 />
      <Footer />
    </>
  );
};
