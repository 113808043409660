import React from "react";
import { Header } from "../Header/Header";
import {
  BgOpacity,
  BreadcrumbsTitle,
  BreadcrumbsWrapper,
  HeaderContent,
  HeaderSubContent,
  PageHeaderContainer,
  PageHeaderWrapper,
  PageTitle,
} from "./PageHeaderStyle";

export const PageHeader = ({ headerBg, pageTitle }) => {
  return (
    <PageHeaderWrapper>
      <PageHeaderContainer bg={headerBg}>
        <BgOpacity />
        <HeaderContent>
          <Header marginTop={"-80px"} />
          <HeaderSubContent>
            <PageTitle>{pageTitle}</PageTitle>
            <BreadcrumbsWrapper>
              <img src="/assets/icons/home-24.svg" alt="home" />
              <BreadcrumbsTitle>Home / {pageTitle}</BreadcrumbsTitle>
            </BreadcrumbsWrapper>
          </HeaderSubContent>
        </HeaderContent>
      </PageHeaderContainer>
    </PageHeaderWrapper>
  );
};
