import styled from "styled-components";

export const PageHeaderWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
`;

export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  background: url(${(props) => props.bg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

export const BgOpacity = styled.div`
  position: absolute;
  height: 300px;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
`;

export const HeaderContent = styled.div`
  z-index: 10;
`;

export const PageTitle = styled.div`
  font-size: 48px;
  line-height: 56px;
  width: 100%;
  margin: 70px 0 14.5px;
  color: #fff;
`;

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export const BreadcrumbsTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #fff;
`;

export const HeaderSubContent = styled.div`
  max-width: 1232px;
  padding: 0 16px;
  margin: auto;
`;
