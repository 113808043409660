import React, { useEffect, useState } from "react";
import {
  EnquireBtn,
  FooterBottom,
  FooterColumn,
  FooterContainer,
  FooterLogoDesc,
  FooterLogoTitle,
  FooterTitle,
  FooterTitleItem,
  FooterVectorArt,
  FooterWrapper,
  FormWrapper,
  InputBox,
  SocialWrapper,
  Vector1,
  Vector2,
} from "./FooterStyle";

export const Footer = () => {
  const [email, setEmail] = useState("");
  const [enableBtn, setEnableBtn] = useState(false);
  useEffect(() => {
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    if (validateEmail(email)) {
      setEnableBtn(true);
    } else {
      setEnableBtn(false);
    }
  }, [email]);

  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterVectorArt>
          <Vector1 src="/assets/images/Vector1.png" alt="vector" />
          <Vector2 src="/assets/images/Vector2.png" alt="vector" />
        </FooterVectorArt>
        <FooterColumn>
          <img
            src="/assets/logo/logo-alternate.svg"
            alt="logo"
            height="52px"
            width="64"
          />
          <FooterLogoTitle>SPK International Exports</FooterLogoTitle>
          <FooterLogoDesc>
            Premium Quality. At Competitive Price.
          </FooterLogoDesc>
          <FooterTitle>Need to know more?</FooterTitle>
          <FormWrapper>
            <InputBox
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Your Email here"
            />
            <EnquireBtn
              disabled={!enableBtn}
              onClick={() =>
                enableBtn &&
                window.open(
                  `/contact${email.length > 0 ? "?email=" + email : ""}`,
                  "_self"
                )
              }
            >
              Enquire Now
            </EnquireBtn>
          </FormWrapper>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>Opening Hours</FooterTitle>
          <FooterTitleItem> Mon - Fri, 8AM - 6PM</FooterTitleItem>
          <FooterTitleItem> Sat, 10AM - 5PM</FooterTitleItem>
          <FooterTitleItem> Sun, Holiday</FooterTitleItem>
          <FooterTitle>Connect with us</FooterTitle>
          <SocialWrapper>
            <img
              src="/assets/icons/Twitter.svg"
              alt="twitter"
              // onClick={() => window.open("/contact", "_self")}
            />
            <img
              src="/assets/icons/Facebook.svg"
              alt="facebook"
              // onClick={() => window.open("/contact", "_self")}
            />
            <img
              src="/assets/icons/Linkedin.svg"
              alt="linkedin"
              // onClick={() => window.open("/contact", "_self")}
            />
            <img
              src="/assets/icons/Youtube.svg"
              alt="youtube"
              // onClick={() => window.open("/contact", "_self")}
            />
          </SocialWrapper>
        </FooterColumn>
        <FooterColumn>
          <FooterTitle>Our Location</FooterTitle>
          <FooterTitleItem>
            417/H, 2nd Cross, 9th Main, Vijayanagar, Pipeline Road, Bangalore,
            India
          </FooterTitleItem>
          <FooterTitle>Email Us On</FooterTitle>
          <FooterTitleItem>sales@spkinternationalexports.com</FooterTitleItem>
          <FooterTitle>Call Us On</FooterTitle>
          <FooterTitleItem>+91 90660 77748 | +91 97313 65003</FooterTitleItem>
        </FooterColumn>
      </FooterContainer>
      <FooterBottom>
        SPK International Exports © All rights reserved Copyrights 2020
      </FooterBottom>
    </FooterWrapper>
  );
};
