import React from "react";
import { Fade, Zoom } from "react-reveal";
import {
  BodyContainerSection3,
  HomeSection3,
  Section3Title,
  WallPaperContainer,
} from "../HomeStyle";
export const HomePageSection6 = () => {
  return (
    <HomeSection3>
      <BodyContainerSection3>
        <Zoom>
          <Section3Title>Gallery</Section3Title>
          {/* <Section3Caption>
            lorem ipsum it the simply dummy text lorem ipsum is the simply dummy
            text
          </Section3Caption> */}
        </Zoom>

        <Fade big bottom>
          <WallPaperContainer>
            <div>
              <img src="/assets/images/gallery-orange.webp" alt="1" />
              <img src="/assets/images/gallery-storage.webp" alt="1" />
              <img src="/assets/images/gallery-capsicum.webp" alt="1" />
              <img src="/assets/images/gallery-transport.webp" alt="1" />
              <img src="/assets/images/gallery-wheat.webp" alt="1" />
              <img src="/assets/images/gallery-rice.webp" alt="1" />
            </div>
          </WallPaperContainer>
        </Fade>
      </BodyContainerSection3>
    </HomeSection3>
  );
};
