import styled from "styled-components";

export const BodyWrapper = styled.div`
  width: 100%;
`;

export const BodyContainer = styled.div`
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  padding: 80px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  color: #373737;
`;
export const SectionCaption = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #686969;
  text-align: center;
  margin: 30px auto 60px;
  width: 80%;
`;

export const ProductsCollectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
`;

export const ProductCardItem = styled.div`
  display: flex;
  gap: 16px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  padding-bottom: 16px;
  > img {
    aspect-ratio: 373/300;
    height: auto;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const ProductName = styled.div`
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  margin: 0 16px;
`;

export const ProductCategory = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin: 0 16px;
`;
