import React from "react";
import { Zoom } from "react-reveal";
import { ReadMoreBtn } from "../../Home/HomeStyle";
import {
  Section3Caption,
  Section3Container,
  Section3Title,
  Section3Wrapper,
} from "../AboutStyle";

export const AboutSection3 = () => {
  return (
    <Section3Wrapper bg="/assets/images/bridge.webp">
      <Section3Container>
        <Zoom>
          <Section3Title>We are the Best for You</Section3Title>
          <Section3Caption>
            Our goal is to provide High Quality Indian Food Products at farmer
            price. We strive to keep our products of outstanding quality,
            guaranteed food safety and grown under circumstances with attention
            to people and the environment.
          </Section3Caption>
          <ReadMoreBtn onClick={() => window.open("/contact", "_self")}>
            Enquire Now
          </ReadMoreBtn>
        </Zoom>
      </Section3Container>
    </Section3Wrapper>
  );
};
