import styled from "styled-components";

export const Section1Wrapper = styled.div`
  width: 100%;
`;

export const Section1Container = styled.div`
  max-width: 1232px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 16px 60px;
`;

export const Section1Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const Section1Image = styled.div`
  > img {
    aspect-ratio: 280/450;
    width: 100%;
    max-width: 350px;
    object-fit: cover;
    object-position: center;
    height: auto;
  }
`;

export const Section1Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 766px;
  gap: 16px;
  justify-content: space-between;
`;

export const Section1TypeText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #2f2f2f;
`;

export const Section1Title = styled.div`
  font-weight: 600;
  font-size: 56px;
  line-height: 64px;
  color: #000000;
`;

export const Section1Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  background: #f9f9f9;
`;

export const Section1Bottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 80px;
  grid-gap: 60px;
`;

export const TopBorderCard = styled.div`
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-top: 4px solid #22356d;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TopBorderCardTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
`;

export const TopBorderCardDesc = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const Section3Wrapper = styled.div`
  width: 100%;
  height: 410px;
  background: url(${(props) => props.bg});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  position: relative;
  z-index: 0;
`;
export const BgOpacity = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
`;

export const Section3Container = styled.div`
  max-width: 1232px;
  width: 100%;
  padding: 0 16px;
  margin: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const Section3Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;

  color: #ffffff;
`;

export const Section3Caption = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 868px;
  color: #ffffff;
`;

export const AboutSection4Wrapper = styled.div`
  width: 100%;
`;

export const AboutSection4Container = styled.div`
  margin: auto;
  padding: 80px 16px;
  max-width: 1232px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

export const LeftSideSection4 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  justify-content: space-between;
`;

export const LeftSideSection4Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Section4Ques = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  text-align: center;
`;

export const Section4Ans = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  text-align: center;
`;
export const RightSideSection4 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: calc(100% - 700px);
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > img {
      height: 200px;
      width: 200px;
      object-position: center;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;

export const PersonName = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;

export const PersonDesignation = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #d2153d;
  margin-top: -16px;
`;
