import styled from "styled-components";

export const ContactSection1Wrapper = styled.div`
  width: 100%;
`;

export const ContactSection1Container = styled.div`
  padding: 80px 16px;
  margin: auto;
  width: 100%;
  max-width: 1232px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 32px 54px;
  width: 100%;
`;

export const InputField = styled.input`
  outline: none;
  padding: 24px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  width: 450px;
  font-size: 16px;
`;

export const TextAreaInputField = styled.textarea`
  outline: none;
  padding: 24px;
  background: #ffffff;
  border: none;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  resize: none;
  height: 154px;
  width: 100%;
  font-size: 16px;
`;

export const SubmitBtn = styled.div`
  background: ${(props) => (props.btnColor ? "#22356d" : "grey")};
  height: 40px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  cursor: ${(props) => (props.btnColor ? "pointer" : "default")};
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 950px;
  gap: 34px;
`;

export const ContactSection2Container = styled.div`
  width: 100%;
`;
export const ContactSection2Wrapper = styled.div`
  max-width: 1232px;
  padding: 64px 16px;
  margin: auto;
  width: 100%;
`;
export const MapContainer = styled.div`
  > img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-top: 80px;
`;

export const AddressContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 25px rgba(0, 33, 91, 0.2);
  border-left: 7px solid #22356d;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContactContainer = styled.div`
  background: #ffffff;
  display: flex;
  gap: 16px;
  //   padding: 16px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const ItemTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  text-transform: uppercase;
  color: #41444b;
`;

export const ItemText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #00215b;
`;

export const ContentIcon = styled.div``;

export const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

export const ThankYouText = styled.div`
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  color: #000000;
`;
