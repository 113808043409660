import React from "react";
import { Zoom } from "react-reveal";
import {
  BodyContainer,
  HomeSection1,
  HomeSection1Caption,
  HomeSection1ImageContainer,
  HomeSection1SubTitle,
  HomeSection1Title,
  HomeSectionContent,
  ImageWrapper,
  ReadMoreBtn,
  SectionTypeText,
} from "../HomeStyle";

export const HomePageSection1 = () => {
  return (
    <HomeSection1>
      <Zoom>
        <BodyContainer>
          <HomeSectionContent>
            <SectionTypeText>About Us</SectionTypeText>
            <HomeSection1Title>SPK - Global Food Exporters</HomeSection1Title>
            {/* <HomeSection1Title>GLOBAL FOOD PRODUCTS</HomeSection1Title> */}
            <HomeSection1SubTitle>
              We Guarantee High Quality Products at competitive Price
            </HomeSection1SubTitle>
            <HomeSection1Caption>
              “As the ‘land of spices’, India exports tons of various kinds of
              food. In fact, food export from India is a major source of
              revenue. To help your products reach to even the farthest corners
              of the world, SPK offers you various types of Basmati &
              Non-Basmati Rice export as well as spices from India.
            </HomeSection1Caption>
            <ReadMoreBtn onClick={() => window.open("/about", "_self")}>
              Read More
              <img src="/assets/icons/long-arrow-right.svg" alt="read more" />
            </ReadMoreBtn>
          </HomeSectionContent>
          <HomeSection1ImageContainer>
            <ImageWrapper>
              <video
                width="383px"
                height="350px"
                playsInline
                autoPlay
                muted
                loop
                src="/assets/images/bg-video.mp4"
              />
              {/* <source type="video/mp4" /> */}
              {/* <source src="movie.ogg" type="video/ogg" /> */}
              {/* </video> */}
              {/* <img src="/assets/images/warehouse-boxes.png" alt="" /> */}
            </ImageWrapper>
          </HomeSection1ImageContainer>
        </BodyContainer>
      </Zoom>
    </HomeSection1>
  );
};
