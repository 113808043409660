import React from "react";
import { Fade } from "react-reveal";
import {
  AddressContainer,
  BottomContainer,
  ContactContainer,
  ContactSection2Container,
  ContactSection2Wrapper,
  ContentIcon,
  ItemText,
  ItemTitle,
  MapContainer,
} from "../ContactStyle";

export const ContactSection2 = () => {
  return (
    <ContactSection2Container>
      <ContactSection2Wrapper>
        <Fade bottom>
          <MapContainer>
            <img src="/assets/images/google-map.png" alt="google map" />
          </MapContainer>
        </Fade>
        <Fade bottom>
          <BottomContainer>
            <AddressContainer>
              <ItemTitle>OFFICE Address</ItemTitle>
              <ItemText>
                417/H, 2nd Cross, 9th Main, Vijayanagar, Pipeline Road,
                Bangalore, India
              </ItemText>
            </AddressContainer>
            <ContactContainer>
              <ContentIcon>
                <img src="/assets/icons/call.svg" alt="call " />
              </ContentIcon>
              <div>
                <ItemTitle>Company Phones</ItemTitle>
                <ItemText>
                  +91 90660 77748 <br /> +91 97313 65003
                </ItemText>
              </div>
            </ContactContainer>
            <ContactContainer>
              <ContentIcon>
                <img src="/assets/icons/email.svg" alt="email " />
              </ContentIcon>
              <div>
                <ItemTitle>E-mail Address</ItemTitle>
                <ItemText>sales@spkinternationalexports.com</ItemText>
              </div>
            </ContactContainer>
          </BottomContainer>
        </Fade>
      </ContactSection2Wrapper>
    </ContactSection2Container>
  );
};
