import Fade from "react-reveal/Fade";
import {
  Section1Body,
  Section1Bottom,
  Section1Container,
  Section1Content,
  Section1Description,
  Section1Image,
  Section1Title,
  Section1TypeText,
  Section1Wrapper,
  TopBorderCard,
  TopBorderCardDesc,
  TopBorderCardTitle,
} from "../AboutStyle";
export const AboutSection1 = () => {
  return (
    <Section1Wrapper>
      <Section1Container>
        <Section1Body>
          <Fade left>
            <Section1Image>
              <img src="/assets/images/about-1.webp" alt="containers" />
            </Section1Image>
          </Fade>
          <Fade right>
            <Section1Content>
              <Section1TypeText>About Us</Section1TypeText>
              <Section1Title>
                We 're providing the best customer service
              </Section1Title>
              <Section1Description>
                SPK International Exports started in 2022. this company was
                started with a different entity name by our father, focusing on
                providing food services. With immense experience in the food
                industry, SPK exports of ​all kinds of​ Indian spices​, pulses,
                grains, Fruits, Vegetables, and many more, to a number of
                destinations around the globe. ​With several partnerships with
                buyers across multiple countries, we have grown to be among the
                go-to companies for food export from India.
                <br />
                <br />
                India is the World’s Largest producer of Rice. It contributes
                21.5 percent of Global Rice production. Within the country, Rice
                occupies one-quarter of the total cropped area and it
                contributes about 40 to 43 percent of total Food Grain
                production and it continues to play a vital role in the National
                exports.
                <br />
                <br /> With immense experience in the food industry, we also
                Export other Food Products like Indian Spices​, Pulses, Grains,
                Fruits, Vegetables, and many more, to a number of destinations
                around the globe. ​With several partnerships with buyers across
                multiple countries, we have grown to be among the go-to
                companies for food export from India.
              </Section1Description>
            </Section1Content>
          </Fade>
        </Section1Body>
        <Fade bottom>
          <Section1Bottom>
            <TopBorderCard>
              <TopBorderCardTitle>Our Mission</TopBorderCardTitle>
              <TopBorderCardDesc>
                To cultivate, manufacture, process and serve best in class food
                retaining freshness and hygiene, fit for daily consumption.
              </TopBorderCardDesc>
            </TopBorderCard>
            <TopBorderCard>
              <TopBorderCardTitle>Our Vision</TopBorderCardTitle>
              <TopBorderCardDesc>
                Is to Provide High End Quality Organic Products. We believe in
                creating that space for Trust & healthy products. India is known
                for it's Healthy & Organic products
              </TopBorderCardDesc>
            </TopBorderCard>
          </Section1Bottom>
        </Fade>
      </Section1Container>
    </Section1Wrapper>
  );
};
